@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
}

input:-internal-autofill-selected,
input:-webkit-autofill {
  background-color: transparent !important;
}

.number-field-buttons {
  appearance: textfield; /* Standard property for controlling element appearance */
  -moz-appearance: textfield; /* Firefox-specific property */
}

.number-field-buttons::-webkit-outer-spin-button,
.number-field-buttons::-webkit-inner-spin-button {
  -webkit-appearance: none; /* Removes default styling provided by the browser */
  margin: 0; /* Removes margin around the buttons */
}
.grid-card {
  img {
    transition: all 300ms ease-in-out 250ms;
  }

  .banner-content {
    transition: all 250ms ease-in-out;
  }
}

.banner-drag > .react-resizable-handle.react-resizable-handle-se {
  z-index: 999;
}

.grid-card:hover {
  @apply shadow-md;

  img {
    transform: scale(1.015);
  }

  .banner-content {
    transform: scale(1.025);
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.category-html {
  h1 {
    @apply text-3xl font-bold leading-7 mb-3;
  }

  h2 {
    @apply text-2xl font-bold leading-6 mb-2;
  }

  p {
    @apply text-base;
  }
}

.positive {
  fill: transparent;
  stroke: #00b8ab;
  stroke-width: 3px;
}
.negative {
  fill: transparent;
  stroke: red;
  stroke-width: 3px;
}

.positive-area {
  fill: url(#positive-gradient);
}

.negative-area {
  fill: url(#negative-gradient);
}

.ct-line {
  fill: transparent;
  stroke-width: 2px;
}

.negative .ct-line {
  stroke: #dd0008;
}

.positive .ct-line {
  stroke: #00b8ab;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #ffc952;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Basic editor styles */
.ProseMirror {
  margin-top: 4px;
  padding: 0 16px;
  /* 60px = toolbar + label */
  height: calc(100% - 60px);

  font-family: var(--font-inter), sans-serif;

  border: 1px solid #bfbfbf;
  border-radius: 4px;
}

.ProseMirror-focused {
  outline: 4px solid #e6f1fc;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px #ffc952,
    0 0 5px #ffc952;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #29d;
  border-left-color: #29d;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.uppy-Root {
  z-index: 120 !important;
}

.dragdropzone {
  z-index: 80 !important;
}

.uppy-Dashboard-AddFiles-list {
  flex: 0 !important;
}

.PinturaButtonExport {
  background-color: #0077dd !important;
  border-radius: 0.5rem !important;
  color: white !important;
  padding: 0.5rem 0.875rem !important;
  font-size: 0.875rem !important;
}

/* Main calendar styles */
.react-calendar {
  --gray: #dadada;
  --fontSize: 0.875rem;
  --primary: #006edc;
  --primaryLight: #bfddf7;
  --border: #bfbfbf;
  border: transparent !important;
  padding: 1rem;
  box-shadow:
    0px 4px 8px -2px rgba(16, 24, 40, 10%),
    0px 2px 4px -2px rgba(16, 24, 40, 6%);
}

/* Date picker general styles */
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  font-family: 'Inter', sans-serif !important;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-date-picker,
.react-daterange-picker {
  height: 100%;
  width: 100%;
}

.react-daterange-picker__calendar.react-daterange-picker__calendar--open {
  inset: 100% 0 0 !important;
}

.react-daterange-picker__inputGroup__leadingZero,
.react-date-picker__inputGroup__leadingZero {
  color: black;
}

/* Wrapper styles for date picker and range picker */
.react-date-picker__wrapper,
.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border-color: #bfbfbf !important;
  color: #bfbfbf;
  padding: 0.625rem 0.875rem;
  border-radius: 0.5rem;
  flex-direction: row-reverse;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  font-family: 'Inter', sans-serif;
}

.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-date-picker__button:enabled {
  cursor: pointer;
}

.react-date-picker__calendar-button.react-date-picker__button,
.react-daterange-picker__calendar-button.react-daterange-picker__button {
  color: var(--border);
}

.react-date-picker__inputGroup__divider,
.react-daterange-picker__inputGroup__divider {
  display: none;
}

select.react-daterange-picker__inputGroup__input {
  -moz-appearance: none;
  -webkit-appearance: none;
}

select.react-daterange-picker__inputGroup__input::-ms-expand {
  display: none;
}

.react-date-picker__calendar.react-date-picker__calendar--open,
.react-daterange-picker__calendar.react-daterange-picker__calendar--open {
  overflow: visible;
  z-index: 30;
}

.react-date-picker__calendar,
.react-daterange-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: white !important;
  border-radius: 0.5rem;
  border: 0 !important;
  overflow: hidden;
  box-shadow:
    0px 20px 24px -4px rgba(16, 24, 40, 0.1),
    0px 8px 8px -4px rgba(16, 24, 40, 0.04);
}

/* Navigation styles */
.react-calendar__navigation {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  background-color: transparent !important;
  min-width: 44px;
  background: none;
  font-size: 1rem;
}

.react-calendar__tile {
  text-align: center;
  aspect-ratio: 1;
  background-color: transparent !important;
  border-radius: 50%;
  margin: 0.25rem 0 0 0 !important;
  color: black;
}

.react-calendar__tile.react-calendar__month-view__days__day:disabled {
  color: lightgray;
}

.react-calendar__month-view__weekdays__weekday {
  text-transform: initial;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
  font-size: var(--fontSize);
}

/* Active and hover styles for tiles */
.react-calendar__tile--now {
  background-color: var(--gray) !important;
}

.react-calendar__tile--active {
  background: var(--primary) !important;
  color: white;
}

.react-calendar__tile--active.react-calendar__tile--rangeStart,
.react-calendar__tile--active.react-calendar__tile--rangeEnd {
  background-color: var(--primaryLight) !important;
}

.react-calendar__tile--hover {
  border-radius: 50%;
  background-color: var(--primaryLight) !important;
  position: relative;
  z-index: 2;
}
/* Hover and Range-specific Styles */
.react-calendar__tile--hoverEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.react-calendar__tile--hoverStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.react-calendar__tile--hover,
.react-calendar__tile--range {
  background-color: var(--primary);
  border-radius: 0;
}

.react-calendar__tile--range {
  position: relative;
  z-index: 2;
  background-color: var(--primaryLight);
}

.react-calendar__tile--rangeStart,
.react-calendar__tile--hoverStart {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.react-calendar__tile--rangeEnd,
.react-calendar__tile--hoverEnd {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.react-calendar__tile--range.react-calendar__tile--active {
  color: black;
}

.react-calendar__tile--range.react-calendar__tile--rangeStart,
.react-calendar__tile--range.react-calendar__tile--rangeEnd,
.react-calendar__tile--hoverStart,
.react-calendar__tile--hoverEnd {
  color: white;
}

/* Before styles for hover and range effects */
.react-calendar__tile--rangeEnd::before,
.react-calendar__tile--hover.react-calendar__tile--hoverEnd::before,
.react-calendar__tile--rangeStart::before,
.react-calendar__tile--hover.react-calendar__tile--hoverStart::before {
  position: absolute;
  content: '';
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 50%;
  background-color: var(--primary);
}

.react-calendar__tile--rangeStart::before,
.react-calendar__tile--hover.react-calendar__tile--hoverStart::before {
  left: 0;
}

.react-calendar__tile--rangeEnd::before,
.react-calendar__tile--hover.react-calendar__tile--hoverEnd::before {
  right: 0;
}

/* Additional styles for tile radius adjustments */
.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day--weekend
  + .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day:not(
    .react-calendar__month-view__days__day--weekend
  ),
.react-calendar__tile.react-calendar__tile--hover.react-calendar__month-view__days__day--weekend
  + .react-calendar__tile.react-calendar__tile--hover.react-calendar__month-view__days__day:not(
    .react-calendar__month-view__days__day--weekend
  ) {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.react-calendar__tile--range.react-calendar__tile--hover.react-calendar__tile--hoverStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.react-calendar__tile--range.react-calendar__tile--active.react-calendar__tile--rangeEnd:not(
    .react-calendar__tile--rangeBothEnds
  ),
.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--hoverEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.react-calendar__tile--rangeStart.react-calendar__tile--hoverEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.react-calendar__tile--hover.react-calendar__tile--range.react-calendar__tile--hoverBothEnds.react-calendar__tile--rangeBothEnds {
  border-radius: 50%;
}

.react-calendar__tile--range.react-calendar__tile--active.react-calendar__month-view__days__day--weekend
  + .react-calendar__tile--active.react-calendar__tile--range.react-calendar__month-view__days__day--weekend,
.react-calendar__tile--hover.react-calendar__month-view__days__day--weekend
  + .react-calendar__tile--hover.react-calendar__month-view__days__day--weekend {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

/* Navigation Arrow Styles */
.react-calendar__navigation__arrow {
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  font-size: 2rem !important;
  min-width: unset !important;
}

/* Input Group Styles */
.react-date-picker__inputGroup__input {
  padding: 0.811111111px !important;
  margin-right: 6.5px;
}

.react-date-picker__inputGroup__input--hasLeadingZero {
  padding-left: 9px !important;
  width: 20px !important;
  /* Styles for .keen-slider that is not disabled */
  .keen-slider:not([data-keen-slider-disabled]) {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    align-content: flex-start;
    display: flex;
    overflow: hidden;
    position: relative;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 100%;
  }
}

/* Styles for slides inside an active .keen-slider */
.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
  min-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

/* Reverse direction for .keen-slider with specific data attribute */
.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
  flex-direction: row-reverse;
}

/* Wrap flex items for vertical .keen-slider with specific data attribute */
.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v] {
  flex-wrap: wrap;
}

/* Base styles for .keen-slider__slide */
.keen-slider__slide {
  flex-shrink: 0;
  height: 100%;
}
